import React from "react";
import Layout from '../components/layout'
import Hero from "../components/hero";
import HeroImage from "../images/the-couple-lr.jpg";
import BillyPic from '../images/billyswedding.jpg'
import Ryker from '../images/ryker.jpg'
import Berlin from '../images/berlin.jpg'
import SamAndSeph from '../images/samsephwedding.jpg'
import RykerAndUs from '../images/rykerandus.jpg'
import './the-couple.scss'
const backgroundImageStyle = (url, position = 'top center') => {
  return {
    backgroundImage: `url(${url})`,
    backgroundSize: 'cover',
    backgroundPosition: position
  }
}

export default () => {
  return (
    <Layout hero={<Hero img={HeroImage} className="desktop-only" />}>
      <Hero className="mobile-only" img={HeroImage}/>
      <div>
        <div className='flex flex-col md:flex-row h-auto pic-box'>
          <div className='bg-op-green relative w-full md:w-50 text-blue-dark flex flex-col'>
            <div className='p-8 flex-grow flex flex-col justify-content-center'>
              <div className='headline'>our story</div>
              <p>
                {"We met in college though we didn’t truly get to know each other and begin dating until grad school. Over four years since our first date, we've changed career paths, moved in together, traveled the country, backpacked across Europe, and introduced a furry pup to our family. In July of 2018, Frank popped the question at home after dinner at Charlie's (where we had our first date), and Krystal said yes. We can't wait for this exciting new chapter to begin!"}
              </p>
              </div>
            <div>
              <div className='hidden md:block bg-blue text-white text-right'>
                <div className='bg-slate-blue pt-12 px-8'>
                  <div className='headline'>
                    Frank
                  </div>
                  <p>
                    web developer
                  </p>
                  <p>
                    master chef
                  </p>
                </div>
                <div className='bg-slate py-2 px-8'>
                  <p>
                    Saints fan
                  </p>
                  <p className='mb-0'>
                    watched that Ken Burns documentary
                  </p>
                  <p> </p>
                </div>
              </div>
            </div>
          </div>
          <div className='billy flex flex-col justify-end w-full md:w-50' style={backgroundImageStyle(BillyPic, 'top center')}>
            <div className='flex items-end justify-between'>
              <p className='pb-8 px-8 cap hidden md:block'>
              {"Billy & Rachel's wedding 2015"}
              </p>
              <div className='md:hidden'>
                <div className='md:pt-2 px-8'>
                  <div className='headline'>
                    Frank
                  </div>
                  <p>
                    web developer
                  </p>
                  <p>
                    master chef
                  </p>
                </div>
                <div className='md:pt-2 px-8'>
                  <p>
                    Saints fan
                  </p>
                  <p className='mb-0'>
                    watched that Ken Burns documentary
                  </p>
                  <p> </p>
                </div>
              </div>
              <div>
                <div className='md:pt-2 px-8'>
                  <div className='headline'>
                    Krystal
                  </div>
                  <p>
                    phd <span className="line-through">student</span> !!
                  </p>
                  <p>
                    master eater
                  </p>
                </div>
                <div className='px-8'>
                  <p>
                    Korean karaoke pro
                  </p>
                  <p className='mb-0'>
                    Simpsons quote machine
                  </p>
                  <p> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col md:flex-row pic-box-2'>
          <div className='w-full md:w-50'>
            <div className='ryker relative' style={backgroundImageStyle(Ryker)}>
              <div className='desc'>
                <div className='headline'>Ryker</div>
                <p>good boy</p>
              </div>
              <p className='cap'>
               Every day with Ryker
              </p>
            </div>
            <div className='sas-wed relative' style={backgroundImageStyle(SamAndSeph)}>
              <p className='cap'>{"Sam & Seph's wedding 2017"}</p>
            </div>
          </div>
          <div className='w-full md:w-50'>
            <div className='berlin relative' style={backgroundImageStyle(Berlin)}>
              <p className='cap'>Berlin 2018</p>
            </div>
            <div className='ryker-n-us relative' style={backgroundImageStyle(RykerAndUs, 'center')}>
              <p className='cap'>Adopting Ryker 2016</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
